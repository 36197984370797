import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ManagementDefaultRateInterface } from '../interfaces/ManagementDefaultRateInterface';

@Injectable({
  providedIn: 'root',
})
export class ManagementDefaultRateRequestsService {
  constructor(private http: HttpClient) {}

  private baseUrl: string = environment.apiUrlCredit+ '/tasa-mora';

  /**
   * Obtiene la lista de tasas de gestión para el año actual.
   * @returns {Observable<any>} Observable con la respuesta del servidor.
   */
  getListManagementRate() {
    return this.http.get<any>(`${this.baseUrl}/listado/anioActual`);
  }

  /**
   * Guarda las tasas de mora para los meses proporcionados.
   * @param {ManagementDefaultRateInterface[]} monthData - Array con los datos de tasas de mora por mes.
   * @returns {Observable<any>} Observable con la respuesta del servidor.
   */
  setSaveRate(monthData: ManagementDefaultRateInterface[]) {
    const url = `${this.baseUrl}/guardar/mora`;
    const body = { monthData: monthData };
    return this.http.put<any>(url, body);
  }
}
