import { Routes } from '@angular/router';

export const ProvidersRoutes: Routes = [
      {
            path: 'listado',
            loadComponent: () => import('./pages/providers-index/providers-index.component')
      },
      {
            path: ':type',
            loadComponent: () => import('./pages/providers-form-index/providers-form-index.component')
      },
      {
            path: 'solicitudes-creditos/listado',
            loadComponent: () => import('./pages/providers-request-credits/providers-request-credits.component')
      },
      {
            path: ':type/:id',
            loadComponent: () => import('./pages/providers-form-index/providers-form-index.component')
      },
];
