import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebhookService {

        private baseUrl = environment.apiUrlCustomer+'/webhook';

        constructor(private http: HttpClient) {}

        /**
         * Suscribe un webhook.
         * @param {any} data - Los datos para la suscripción del webhook.
         * @returns {Observable<any>} Observable con la respuesta de la suscripción.
         */
        subscribeWebhook(data: any) {
                return this.http.post(`${this.baseUrl}/register-subscription`, data);
        }

        /**
         * Obtiene los webhooks suscritos.
         * @returns {Observable<any>} Observable con la lista de webhooks suscritos.
         */
        whichOnesSubscribed() {
                return this.http.get(`${this.baseUrl}/which-ones-subscribed`);
        }

        /**
         * Obtiene todas las suscripciones por tipo.
         * @returns {Observable<any>} Observable con la lista de todas las suscripciones por tipo.
         */
        allTypeSubscriptions() {
                return this.http.get(`${this.baseUrl}/all-type-susbcriptions`);
        }

        /**
         * Cambia el estado de un tipo de webhook.
         * @param {any} data - Los datos para cambiar el estado del tipo de webhook.
         * @returns {Observable<any>} Observable con la respuesta del cambio de estado.
         */
        changeStatusTypeWebhook(data: any) {
                return this.http.post(`${this.baseUrl}/change-status-type-webhook`, data);
        }

        /**
         * Verifica si el usuario está suscrito.
         * @returns {Observable<any>} Observable con la respuesta de si el usuario está suscrito.
         */
        isUserSubscribed() {
                return this.http.get(`${this.baseUrl}/is-user-subscribed`);
        }
}
