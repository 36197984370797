import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';

import { provideAnimations } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { tokenInterceptor } from './shared/interceptors/TokenInterceptor';
import { errorInterceptor } from './shared/interceptors/error.interceptor';

export const appConfig: ApplicationConfig = {
      providers: [
            provideZoneChangeDetection({ eventCoalescing: true }),
            provideRouter(routes, withViewTransitions({
                  skipInitialTransition: true,
            })),
            provideHttpClient(withInterceptors([
                  tokenInterceptor,
                  errorInterceptor
            ])),
            provideAnimations()
      ]
};
