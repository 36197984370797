import { inject, Injectable } from '@angular/core';
import { CommerceDashboardResponseInterface } from '../interfaces/commerce-dashboard.interface';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export enum MetricType {
  DAY = 'dia',
  MONTH = 'mes'
}

export enum MetricCategory {
  CLIENTS = 'clientes',
  ORDERS = 'órdenes',
  SALES = 'ventas'
}

type PeriodType = 'dia' | 'mes';

@Injectable({providedIn: 'root'})
export class CommerceDashboardService {
    /**
     * La URL base para las solicitudes relacionadas con los clientes en la API.
    */
    private readonly baseUrl = environment.apiUrl + '/comercio';
    private readonly baseUrlCustomers = environment.apiUrlCustomer + "/customers"; 
    private readonly baseUrlCommerce = environment.apiUrlCredit;

   
    private http = inject(HttpClient)


    /**
     * Obtiene los datos del panel de control del cliente desde el servidor.
     *
     * @returns {Observable<CommerceDashboardResponseInterface>} 
    */
    getDataDashboard() {
        const url = `${this.baseUrlCustomers}/reports-home`;
        return this.http.get<CommerceDashboardResponseInterface>(url);
    }

    getDashboardCommerce() {
      const url = `${this.baseUrlCommerce}/commerce-profile/home-panel`;
      return this.http.get<CommerceDashboardResponseInterface>(url);
    }

    private readonly MESSAGE_TEMPLATES: Record<MetricCategory, Record<MetricType, { noActivity: string; change: string }>> = {
      [MetricCategory.CLIENTS]: {
        [MetricType.DAY]: {
          noActivity: 'No ha habido nuevos clientes',
          change: 'desde ayer'
        },
        [MetricType.MONTH]: {
          noActivity: 'No ha habido nuevos clientes este mes',
          change: 'del mes anterior'
        }
      },
      [MetricCategory.ORDERS]: {
        [MetricType.DAY]: {
          noActivity: 'No hubo pedidos hoy ni ayer',
          change: 'desde ayer'
        },
        [MetricType.MONTH]: {
          noActivity: 'No hubo pedidos este mes',
          change: 'este mes'
        }
      },
      [MetricCategory.SALES]: {
        [MetricType.DAY]: {
          noActivity: 'No hubo ventas hoy ni ayer',
          change: 'desde ayer'
        },
        [MetricType.MONTH]: {
          noActivity: 'No hubo ventas este mes',
          change: 'este mes'
        }
      }
    };

    /**
     * Calcula el porcentaje de cambio entre dos valores y retorna un mensaje formateado
     * @param currentValue Valor actual
     * @param previousValue Valor anterior
     * @param type Tipo de métrica (clientes, órdenes, ventas)
     * @param period Período de tiempo (dia, mes)
     * @returns Mensaje formateado con el porcentaje de cambio
     */
    public calculateChange(currentValue: number, previousValue: number, type: MetricCategory, period: MetricType): string {
      if (!this.MESSAGE_TEMPLATES[type]?.[period]) {
        return type in this.MESSAGE_TEMPLATES ? 'Periodo no reconocido' : 'Tipo de calculo no reconocido';
      }

      if (currentValue === 0 && previousValue === 0) {
        return this.MESSAGE_TEMPLATES[type][period].noActivity;
      }

      const percentageChange = this.calculatePercentageChange(currentValue, previousValue);
      if (percentageChange === -100) {
        return 'No ha habido actividad';
      }

      const formattedPercentage = this.formatPercentage(percentageChange);
      return `${formattedPercentage}% ${this.MESSAGE_TEMPLATES[type][period].change}`;
    }

    private calculatePercentageChange(currentValue: number, previousValue: number): number {
      if (previousValue === 0) return 0;
      return ((currentValue - previousValue) / previousValue) * 100;
    }

    private formatPercentage(percentage: number): string {
      const sign = percentage >= 0 ? '+' : '-';
      return `${sign}${Math.abs(percentage).toFixed(2)}`;
    }
  
    
}