import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ModalServiceAlert } from '../components/modal-alert/service/modal-alert.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
    const alertService = inject(ModalServiceAlert);

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 400) {
                // Error de validación
                alertService.openModal(
                    "Error",
                    error.error?.message || 'No se pudo identificar el error, comuniquese con soporte',
                    "error"
                );
            }
            return throwError(() => error);
        })
    );
};