import { Routes } from '@angular/router';

import { WebhookIndexComponent } from './webhook/page/webhook-index/webhook-index.component';
import { HomeDashboardComponent } from './commerce-dashboard/pages/home-dashboard/home-dashboard.component';
import ProvidersRequestCreditsComponent from '../administrative/providers/pages/providers-request-credits/providers-request-credits.component';

export const CommerceRoutes: Routes = [
      {
            path: '',
            children: [
                  {
                        path: 'inicio',
                        component: HomeDashboardComponent
                  },
                  {
                        path: 'subscriptions',
                        component: WebhookIndexComponent
                  }
            ]
      }
];
