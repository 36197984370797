import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { toggleLoader } from '../../../../../shared/helpers/functions-helper.service';
import { CreditRequestCardService } from '../../../services/credit-request-card.service';
import { ModalServiceAlert } from '../../../../../shared/components/modal-alert/service/modal-alert.service';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from '../../../../../shared/components/paginator/paginator.component';
import { CreditRequestCardComponent } from '../credit-request-card/credit-request-card.component';

@Component({
  selector: 'app-credit-request-group',
  templateUrl: './credit-request-group.component.html',
  standalone: true,
  imports: [
    CommonModule,
    PaginatorComponent,
    CreditRequestCardComponent
  ]
})
export class CreditRequestGroupComponent implements OnInit, OnDestroy {
  @Input() type!: 'received' | 'study' | 'preaproved' | 'managed';
  @Input() title: string = '';
  @Input() formValue: any = {};

  requests: any[] = [];
  paginator: any;
  totalRequests: number = 0;
  valueRequests: number = 0;
  currentPage: number = 1;

  private subscription: Subscription | null = null;

  constructor(
    private creditRequestCardService: CreditRequestCardService,
    private loaderService: LoaderService,
    private modalServiceAlert: ModalServiceAlert
  ) { }

  /**
   * Inicializa el componente y carga las solicitudes.
   */
  ngOnInit() {
    this.loadRequests();
  }

  /**
   * Limpia la suscripción al destruir el componente.
   */
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Carga las solicitudes de crédito según el tipo y la página actual.
   */
  loadRequests() {
    toggleLoader(this.loaderService, true);
    this.subscription = this.creditRequestCardService.getCreditRequestsCard(this.type, this.currentPage, this.formValue).subscribe({
        next: (response: any) => {

          const dataKey = `creditRequests`;
          const totalKey = `total`;
          const valueKey = `value`;

          this.paginator = response.data[dataKey];
          this.requests = response.data[dataKey].data;
          this.totalRequests = response.data[totalKey];
          this.valueRequests = response.data[valueKey];
          toggleLoader(this.loaderService, false);
        },
        error: (error: any) => {
          this.modalServiceAlert.openModal('Error', 'Hubo un error al obtener las solicitudes', 'error');
          toggleLoader(this.loaderService, false);
        },
        complete: () => {
          toggleLoader(this.loaderService, false);
        }
      });
  }

  /**
   * Maneja el cambio de página y recarga las solicitudes.
   * @param {number} page - El número de página al que se desea cambiar.
   */
  onPageChange(page: number) {
    this.currentPage = page;
    this.loadRequests();
  }

  /**
   * Capitaliza la primera letra de una cadena.
   * @param {string} s - La cadena a capitalizar.
   * @returns {string} La cadena con la primera letra en mayúscula.
   */
  private capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  /**
   * Actualiza los datos, reiniciando la página y los filtros.
   */
  refreshData() {
    this.currentPage = 1;
    this.formValue = {};
    this.loadRequests();
  }

  /**
   * Filtra los datos según el formulario proporcionado.
   * @param {any} form - El objeto de formulario con los filtros a aplicar.
   */
  filterData(form: any) {
    this.currentPage = 1;
    this.formValue = form;
    this.loadRequests();
  }
}
