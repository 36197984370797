import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CreditLineInterface, CustomerHistorialInterface } from '../../../interfaces';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { formatCurrency, formatCurrencyInput, getNumericOnly, toggleLoader, validateCampo } from '../../../helpers/functions-helper.service';
import { CreditLineService } from '../../../../administrative/credit-lines/services/credit-line.service';
import { CreditsService } from '../../../../administrative/credits/services/credits.service';
import { LoaderService } from '../../../services/loader.service';
import { finalize, Observable, tap } from 'rxjs';
import { ModalServiceAlert } from '../../modal-alert/service/modal-alert.service';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomersSearchComponent } from '../customers-search/customers-search.component';
import { SearchStateService } from '../../../services/flag-customer.service';


@Component({
  selector: 'app-withdraw-credit',
  standalone: true,
  imports: [ ReactiveFormsModule, TitleCasePipe, CommonModule, NgSelectModule ],
  templateUrl: './withdraw-credit.component.html',
  styleUrl: './withdraw-credit.component.scss'
})
export class WithdrawCreditComponent {
/**
   * @property {TemplateRef<WithdrawCreditComponent>} modalRequestWithdrawalContent - Referencia al template del modal de solicitud de retiro.
   */
@ViewChild("modalRequestWithdrawal", { static: true }) modalRequestWithdrawalContent!: TemplateRef<WithdrawCreditComponent>;



/**
 * @property {FormGroup} formWithdrawCredit - Formulario para el retiro de crédito.
 */
formWithdrawCredit!: FormGroup;

/**
 * @property {CreditLineInterface[]} creditsLinesShop - Líneas de crédito disponibles en la tienda.
 */
creditsLinesShop: CreditLineInterface[] = [];

/**
 * @property {CustomerHistorialInterface} customer - Información del cliente.
 */
customer!: CustomerHistorialInterface;

/**
 * @property {NgbModalRef} modalRef - Referencia al modal abierto.
 */
private modalRef!: NgbModalRef;

/**
 * @property {number} shopCommerceId - ID de la tienda/comercio.
 */
public shopCommerceId!: number | undefined;

/**
 * @property {number} creditId - ID del crédito.
 */
public creditId: number = 0;

/**
 * @property {Function} validateCampo - Función para validar campos.
 */
readonly validateCampo = validateCampo;

/**
 * @property {Function} formatCurrencyInput - Función para formatear entrada de moneda.
 */
readonly formatCurrencyInput = formatCurrencyInput;

/**
 * @property {Function} formatCurrency - Función para formatear moneda.
 */
readonly formatCurrency = formatCurrency;

/**
 * @property {Function} getNumericOnly - Función para obtener solo valores numéricos.
 */
readonly getNumericOnly = getNumericOnly;

/**
 * @property {boolean} flagShowCodeInput - Bandera para mostrar el input de código.
 */
flagShowCodeInput = false;

/**
 * @property {boolean} flagConfirmCreditAction - Bandera para confirmar la acción de crédito.
 */
flagConfirmCreditAction = false;

/**
 * @property {boolean} flagReadonly - Bandera para establecer campos como solo lectura.
 */
flagReadonly = false;

/**
 * @constructor
 * @param {FormBuilder} formBuilder - Servicio para construir formularios.
 * @param {NgbModal} modalService - Servicio para manejar modales.
 * @param {CreditLineService} creditLineService - Servicio para manejar líneas de crédito.
 * @param {CreditsService} creditService - Servicio para manejar créditos.
 * @param {LoaderService} loaderService - Servicio para manejar el loader.
 * @param {ModalServiceAlert} alertService - Servicio para manejar alertas modales.
 */
constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private creditLineService: CreditLineService,
    private creditService: CreditsService,
    private loaderService: LoaderService,
    public alertService: ModalServiceAlert,
    private searchStateService: SearchStateService
) {
    this.initForm();
}

/**
 * @method ngOnInit
 * @description Método del ciclo de vida que se ejecuta al inicializar el componente.
 */
ngOnInit(): void {
    // this.setupFormCreditLineChanges();
}

/**
 * @method openModalWithdrawCreditComponent
 * @description Abre el modal de retiro de crédito.
 * @param {number} valueCredit - Valor del crédito.
 * @param {number} shopCommerceId - ID de la tienda/comercio.
 * @param {CustomerHistorialInterface} customer - Información del cliente.
 */
openModalWithdrawCreditComponent(valueCredit: number, shopCommerceId: number | undefined, customer: CustomerHistorialInterface): void {
    this.shopCommerceId = shopCommerceId;
    this.customer = customer;
    this.formWithdrawCredit.patchValue({ valueCredit: formatCurrency(valueCredit, false) });
    this.flagConfirmCreditAction = false;
    this.openModal();
    // this.getCreditsLinesByShop(shopCommerceId).pipe(
    //     finalize(() => this.openModal())
    // ).subscribe();
}

/**
 * @method verifyOrderNumberAndSendCodeOtp
 * @description Verifica el número de orden y envía el código OTP.
 */
verifyOrderNumberAndSendCodeOtp(): void {
    if (!this.validateForm()) return;

    if (this.flagShowCodeInput) {
        this.sendCodeOtp();
        return;
    }

    this.verifyOrderNumber().pipe(
        tap((response) => {
            if (response && response.ok) {
                this.sendCodeOtp();
            } else if (response && !response.ok && response.code === 409) {
                this.alertService.openModal("Error", "El número de orden ya existe en el sistema.", "error");
            }
        })
    ).subscribe({
        next: (response) => {
        },
        error: (error) => {
            this.alertService.openModal("Error", error.error.message, "error");
        }
    });
}

/**
 * @method validateCodeOtp
 * @description Valida el código OTP ingresado.
 */
validateCodeOtp(): void {
    const { codeSendToCustomer } = this.formWithdrawCredit.value;
    if (!codeSendToCustomer || this.creditId === 0) {
        this.showError('Por favor, complete el código', 'Error al validar código OTP');
        return;
    }

    this.toggleLoader(true);
    this.creditService.validateCodeOtp(codeSendToCustomer, this.creditId).pipe(
        finalize(() => this.toggleLoader(false))
    ).subscribe(
        (response : any) => {
            if (response.ok) {
                this.flagConfirmCreditAction = true;
                this.showSuccess('Códigos validados correctamente, ya puedes confirmar el crédito');
            } else {
                this.showError(response.message || 'Ocurrió un error al validar el código OTP');
            }
        },
        (error: any )=> this.handleError(error, 'Ocurrió un error al validar el código OTP')
    );
}

/**
 * @method applyCredit
 * @description Aplica el crédito.
 */
applyCredit(): void {
    if (this.creditId === 0) {
        this.showError('Error al realizar el crédito');
        return;
    }

    this.toggleLoader(true);
    this.creditService.applyCredit(this.creditId).pipe(
        finalize(() => this.toggleLoader(false))
    ).subscribe(
        (response: any) => {
            if (response.ok) {
                const { valueCredit } = this.formWithdrawCredit.value;
                this.closeModalWithdrawCredit();
                if (this.customer && this.customer.identification) {
                    this.showSuccess(`El crédito del cliente ${this.customer.identification} por valor: ${valueCredit}, ha sido aplicado correctamente`);
                } else {
                    this.showError('Información del cliente no disponible');
                }
            } else {
                this.showError(response.message || 'Ocurrió un error al aplicar el crédito');
            }
        },
        (error: any) => this.handleError(error, 'Ocurrió un error al aplicar el crédito')
    );
}

/**
 * @method formatCurrencyInputAction
 * @description Formatea la entrada de moneda.
 * @param {Event} event - Evento de entrada.
 * @param {boolean} showSymbol - Indica si se debe mostrar el símbolo de moneda.
 */
formatCurrencyInputAction(event: Event, showSymbol: boolean): void {
    formatCurrencyInput(this.formWithdrawCredit, 'valueCredit', event, showSymbol);
}

/**
 * @method calculateQuote
 * @description Calcula la cuota del crédito.
 */
calculateQuote(): void {
    const { valueCredit,order_number } = this.formWithdrawCredit.value;
    const numberCuotesCredit = 1;


    if (!numberCuotesCredit || !valueCredit  || !order_number) {
        this.alertService.openModal( "Advertencia", "Por favor complete todos los campos antes de calcular la cuota", "warning" )
        return; 
    }


    this.toggleLoader(true);
    this.creditService.getQuoteValue(numberCuotesCredit, valueCredit).pipe(
        finalize(() => this.toggleLoader(false))
    ).subscribe(
       ( response : any) => {
            if (response.ok) {
                this.formWithdrawCredit.patchValue({ valueCuote: response.data });
            } else {
                this.showError('Ocurrió un error al obtener el valor de la cuota');
            }
        },
        (error : any) => this.handleError(error, 'Ocurrió un error en la solicitud')
    );
}

/**
 * @method closeModalWithdrawCredit
 * @description Cierra el modal de retiro de crédito.
 */
closeModalWithdrawCredit(): void {
    this.modalRef.close();
    this.searchStateService.setFlagSearchCustomer(false);
    // Emitir evento para limpiar el componente padre
    this.modalService.dismissAll();
}

/**
 * @method dismissModalWithdrawCredit
 * @description Descarta el modal de retiro de crédito.
 */
dismissModalWithdrawCredit(): void {
    this.modalRef.dismiss();
}

/**
 * @method initForm
 * @private
 * @description Inicializa el formulario de retiro de crédito.
 */
public initForm(): void {
    this.formWithdrawCredit = this.formBuilder.group({
        order_number: ['', Validators.required],
        valueCredit: ['', Validators.required],
        valueCuote: ['', Validators.required],
        codeSendToCustomer: ['', Validators.required],
    });
}

/**
 * @method setupFormCreditLineChanges
 * @private
 * @description Configura los cambios en la línea de crédito del formulario.
 */
public setupFormCreditLineChanges(): void {
    this.formWithdrawCredit.get('creditLine')?.valueChanges.subscribe(() => {
        this.handleCreditLineChange();
    });
}

/**
 * @method handleCreditLineChange
 * @private
 * @description Maneja los cambios en la línea de crédito.
 */
public handleCreditLineChange(): void {
    this.toggleLoader(true);
    const selectedCreditLineId = this.formWithdrawCredit.get('creditLine')?.value;
    const selectedCreditLine = this.creditsLinesShop.find(creditLine => creditLine.id == selectedCreditLineId);

    if (selectedCreditLine?.total_months === 1) {
        this.formWithdrawCredit.patchValue({ numberCuotesCredit: 1 });
    } else {
        this.formWithdrawCredit.patchValue({ numberCuotesCredit: null });
    }

    this.toggleLoader(false);
}

/**
 * @method getCreditsLinesByShop
 * @private
 * @description Obtiene las líneas de crédito por tienda.
 * @param {number} shopCommerceId - ID de la tienda/comercio.
 * @returns {Observable<any>} Observable con la respuesta del servicio.
 */
public  getCreditsLinesByShop(shopCommerceId: number | undefined): Observable<any> {
    return this.creditLineService.getCreditsLinesByShop(shopCommerceId!).pipe(
        tap(response => {
            if (response.ok) {
                this.creditsLinesShop = response.data;
            } else {
                this.showError('Ocurrió un error al obtener las líneas de crédito');
            }
        }),
        finalize(() => this.toggleLoader(false))
    );
}

/**
 * @method openModal
 * @private
 * @description Abre el modal de retiro de crédito.
 */
public openModal(): void {
    this.modalRef = this.modalService.open(this.modalRequestWithdrawalContent, { size: 'lg' });
}

/**
 * @method validateForm
 * @private
 * @description Valida el formulario de retiro de crédito.
 * @returns {boolean} True si el formulario es válido, false en caso contrario.
 */
public validateForm(): boolean {
    const { valueCredit, valueCuote, order_number } = this.formWithdrawCredit.value;
    const numberCuotesCredit = 1;

    if (!numberCuotesCredit || !valueCredit || !valueCuote  || !order_number) {
        this.showError('Por favor, complete todos los campos');
        return false;
    }

        if (this.getNumericOnly(String(valueCredit)) != this.getNumericOnly(String(valueCuote))) {
            this.showError('Los valores de crédito y cuota deben ser iguales, de click en calcular cuota');
            return false;
        }

    return true;
}

/**
 * @method verifyOrderNumber
 * @private
 * @description Verifica el número de orden.
 * @returns {Observable<any>} Observable con la respuesta del servicio.
 */
public verifyOrderNumber(): Observable<any> {
    const { order_number } = this.formWithdrawCredit.value;
    
    this.toggleLoader(true, 'Verificando número de orden...');
    return this.creditService.verifyOrderNumberNotExists(order_number).pipe(
        finalize(() => this.toggleLoader(false))
    );
}

 /**
   * @method sendCodeOtp
   * @private
   * @description Envía el código OTP al cliente.
   */
 public sendCodeOtp(): void {
    const { order_number, valueCredit, valueCuote, creditLine } = this.formWithdrawCredit.value;
    const numberCuotesCredit = 1;

    this.toggleLoader(true, 'Enviando código OTP...');
    
    console.log(order_number, valueCredit, valueCuote, creditLine);

    this.creditService.sendCodeOtp(
        order_number,
        numberCuotesCredit,
        valueCredit,
        valueCuote,
        this.customer.id,
        this.shopCommerceId,
    ).pipe(
        finalize(() => this.toggleLoader(false))
    ).subscribe(
        (response: any) => {
            if (response.ok) {
                this.flagShowCodeInput = true;
                this.creditId = response.data.id;
                this.showSuccess('Código OTP enviado correctamente');
            } else {
                this.showError(response.message || 'Ocurrió un error al enviar el código OTP');
            }
        },
        (error: any )=> this.handleError(error, 'Ocurrió un error al enviar el código OTP')
    );
}

/**
 * @method toggleLoader
 * @private
 * @description Muestra u oculta el loader.
 * @param {boolean} show - Indica si se debe mostrar el loader.
 * @param {string} [message] - Mensaje opcional para mostrar con el loader.
 */
public toggleLoader(show: boolean, message?: string): void {
    toggleLoader(this.loaderService, show, message);
}

/**
 * @method showError
 * @private
 * @description Muestra un mensaje de error en un modal.
 * @param {string} message - Mensaje de error a mostrar.
 * @param {string} [title='Error'] - Título del modal de error.
 */
public showError(message: string, title: string = 'Error'): void {
    this.alertService.openModal(title, message, 'error');
}

/**
 * @method showSuccess
 * @private
 * @description Muestra un mensaje de éxito en un modal.
 * @param {string} message - Mensaje de éxito a mostrar.
 * @param {string} [title='Éxito'] - Título del modal de éxito.
 */
public showSuccess(message: string, title: string = 'Éxito'): void {
    this.alertService.openModal(title, message, 'success');
}

/**
 * @method handleError
 * @description Maneja los errores mostrando un mensaje de error.
 * @param {any} error - Objeto de error.
 * @param {string} defaultMessage - Mensaje de error por defecto.
 */
handleError(error: any, defaultMessage: string): void {
    if (error.error && error.error.message) {
      this.alertService.openModal('Error', error.error.message, 'error');
    } else {
      this.alertService.openModal('Error', defaultMessage, 'error');
    }
  }


/**
 * Cierra el modal y actualiza el estado de búsqueda del cliente.
 * 
 * @param {any} modal - Referencia al modal que se va a cerrar.
 * @returns {void}
 * @public
 */
public closeTheModal(modal: any): void {
    modal.dismiss('Cross click')
    this.searchStateService.setFlagSearchCustomer(false);
}


}
