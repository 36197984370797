import { Routes } from '@angular/router';

/**
 * Rutas para el módulo de pagos de clientes.
 * @constant {Routes} PaymentsCustomerRoutes
 * @description Define las rutas disponibles para la sección de pagos de clientes.
 * Incluye la ruta para mostrar el listado de clientes que carga el componente de forma lazy.
 */
export const PaymentsCustomerRoutes: Routes = [
      {
            path: 'clientes-listado',
            loadComponent: () => import('./payments-customer.component')
      },
      {
            path: 'clientes-listado/:id',
            loadComponent: () => import('./payments-customer.component')
      },
];
