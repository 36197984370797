<div class="card shadow-sm mb-3">
    <div class="card-header p-1 text-center" [ngStyle]="getHeaderStyle(request.request_type)">
        @if (request.request_type === 'Aumento') {
            <div>
                <i class="fa-solid fa-money-bill-trend-up"></i>
                <span class="ps-2">Aumento de cupo</span>
            </div>
        }
        @if (request.request_type === 'Registro') {
            <div>
                <i class="fa-solid fa-user-plus"></i>
                <span class="ps-2">Registro</span>
            </div>
        }
        @if (request.request_type === 'Actualizacion') {
            <div>
                <i class="fa-solid fa-user-pen"></i>
                <span class="ps-2">Actualización</span>
            </div>
        }
    </div>
    <div class="card-body p-4 d-flex flex-column gap-2">
        <span class="fs-4 fw-bold">#{{ request.id }}</span>
        <span class="fs-6">
            <b>Nombre:</b> {{ request?.customer?.name | titlecase }} {{ request?.customer?.last_name | titlecase }}
            <br />
            <b>CC:</b> {{ request?.customer?.identification }}
            <br />
            <b>Solicita:</b> {{ request?.request_value | currency : '$' : "symbol" : '1.0-0' }}
            <br />
            <b>Celular:</b> {{ request?.customer?.customer_phones[0]?.phoneNumber }}
            <br />
            <b>Proveedor:</b> {{ request?.shop_commerce?.shop?.name }}
            <br />
            <b>Sucursal:</b> {{ request?.shop_commerce?.name }}
        </span>
        <div class="d-flex justify-content-between">
            <div class="d-flex gap-1">
                <button class="btn btn-xs btn-info" title="Ver"
                    (click)=" showModalInfoCustomer(request.customer.id, request.id)">
                    <i class="fas fa-eye"></i>
                </button>
                @if (typeGroup !== 'received') {
                    <button type="button" class="btn btn-xs btn-warning"
                        title="Ver Comentarios" (click)="showModalCreditRequestComments(request.id)">
                        <i class="fas fa-comments"></i>
                    </button>
                }
            </div>
            <div class="d-flex gap-1">
                  @if (typeGroup === 'received') {
                      <button class="btn btn-xs btn-success" title="Pasar a Estudio"
                      (click)="confirmAssignment(request.id)">
                      <i class="fas fa-arrow-right"></i>
                    </button>
                    <button class="btn btn-xs btn-success ms-1" title="Pasar a aprobación automática"
                        (click)="confirmAutomaticApproval(request.id, request.customer.id, request.request_value)">
                        <i class="fas fa-check-double"></i>
                    </button>
                }
                @if (typeGroup === 'study') {
                    <button type="button" class="btn btn-xs btn-success"
                        title="Pasar a Preaprobado" (click)="moveToPreapproved(request)">
                        <i class="fas fa-arrow-right"></i>
                    </button>
                }
                @if ((request.request_type === 'Registro' || request.request_type === 'Actualizacion') && typeGroup === 'preaproved') {
                    <button type="button" class="btn btn-xs" style="background-color: #a095ff; color: white"
                        title="Enviar link pagaré" (click)="sendLinkPagare(request)">
                        <i class="fa-solid fa-file-signature"></i>
                    </button>
                }
                @if (typeGroup === 'preaproved') {
                    <button type="button" class="btn btn-xs btn-danger me-1"
                        title="Rechazar solicitud"
                        (click)="handleRejection(request.id)">
                        <i class="fas fa-ban"></i>
                    </button>

                }
                @if (typeGroup === 'preaproved') {
                    <button type="button" class="btn btn-xs btn-success"
                        title="Pasar a gestionados"
                        (click)="verifyPromissoryNoteSignature(request)">
                        <i class="fas fa-arrow-right"></i>
                    </button>
                }
                @if (typeGroup === 'managed') {
                    <button type="button" class="btn btn-xs btn-danger"
                        title="Devolver a Gestionados"
                        (click)="returnToStudy(request.id)">
                        <i class="fas fa-arrow-left"></i>
                    </button>
                }
            </div>
        </div>
    </div>
    @if (typeGroup === 'preaproved' && request.credit_request_state_id === 7) {
        <div class="card-footer text-white p-1" [ngClass]="{'bg-success': request.credit_request_state_id === 7}">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <i class="fas fa-check-circle me-1"></i>
                        <b class="me-1">Preaprobado&nbsp;</b>
                        {{request.value_approve | currency:'$':'symbol':'1.0-0'}}
                    </div>
                </div>
            </div>
        </div>
    }
    @if (typeGroup === 'managed') {
        <div class="card-footer text-white p-1" [ngClass]="{
            'bg-success': request.credit_request_state_id === 3 || request.credit_request_state_id === 5,
            'bg-danger': request.credit_request_state_id !== 3 && request.credit_request_state_id !== 5
        }">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        @if (request.credit_request_state_id === 3 || request.credit_request_state_id === 5) {
                            <i class="fas fa-check-circle me-1"></i>
                            <b class="me-1">
                                {{getStateDescription(request.credit_request_state_id)}}
                            </b>
                            {{request.value_approve | currency : '$' : 'symbol' : '1.0-0'}}
                        } @else {
                            <b class="me-1">
                                {{request.reason_reject}}
                            </b>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
</div>
