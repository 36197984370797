import { ChangeDetectorRef, Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreditRequestListInterface } from "../../interfaces/credits-request-list-interface";
import { CreditRequestService } from "../../services/credit-request.service";
import { TableComponent } from "../../../../shared/components/table/table.component";
import { BreadcrumbComponent } from "../../../../shared/components/breadcrumb/breadcrumb.component";
import { LoaderService } from "../../../../shared/services/loader.service";
import { ModalServiceAlert } from "../../../../shared/components/modal-alert/service/modal-alert.service";
import { TableService } from "../../../../shared/services/table.service";
import { CustomersService } from "../../../customers/services/customers.service";
import { CustomerInfoShowModalComponent } from "../../../../shared/components/modals/customer-info-show-modal/customer-info-show-modal.component";
import { CreditsRequestsCommentsComponent } from "../../../../shared/components/modals/credits-requests-comments/credits-requests-comments.component";
import { DynamicFormModalComponent } from "../../../../shared/components/modals/dynamic-form-modal/dynamic-form-modal.component";
import { exportToExcel, getCurrentTimestamp, toggleLoader } from "../../../../shared/helpers/functions-helper.service";

@Component({
        selector: "app-credits-requests-list",
        templateUrl: "./credits-requests-list.component.html",
        standalone: true,
        imports: [TableComponent, BreadcrumbComponent],
})
export class CreditsRequestsListComponent implements OnInit, OnDestroy {
        breadcrumbItems = [{ label: 'Solicitudes de crédito', url: '/credits-requests' }];
        creditsRequestsList: CreditRequestListInterface[] = [];
        currentPage = 1;
        queryParams: any = {};
        dataTable = {
                table: {
                        header: [
                                { name: '#', code: 'id' },
                                { name: 'Fecha solicitud', code: 'created' },
                                { name: 'Cliente', code: 'customer.fullName', pipe: 'title' },
                                { name: 'Cédula', code: 'customer.identification' },
                                { name: 'Celular', code: 'customer.phone' },
                                { name: 'Analista', code: 'user.name' },
                                { name: 'Proveedor', code: 'shop_commerce.name' },
                                { name: 'Valor Solicitado', code: 'request_value', pipe: 'curren' },
                                { name: 'Valor Aprobado', code: 'value_approve', pipe: 'curren' },
                                { name: 'Estado', code: 'credit_request_state.name', badge: 'credit_request_state.color' },
                        ],
                        body: [],
                        buttons: [
                                { label: 'Ver', icon: 'fa-solid fa-eye', action: 'view', color: 'primary', role: '' },
                                { label: 'Comentarios y observaciones', icon: 'fa-solid fa-comments', action: 'comments-observations', color: 'info', role: '' },
                                { label: 'Devolver a en solicitud', icon: 'fa-solid fa-person-walking-arrow-loop-left', action: 'return-to-request', color: 'warning', role: '' },
                        ],
                        paginator: {}
                },
                search: {
                        dataForm: [
                                { label: "Fecha inicio", control: "dateStart", type: "date" },
                                { label: "Fecha fin", control: "dateEnd", type: "date" },
                                {
                                        label: "Estado", control: "state", type: "select", options: [
                                                { id: 1, name: 'Solicitud' }, { id: 2, name: 'Estudio' }, { id: 3, name: 'Aprobado' },
                                                { id: 4, name: 'Rechazado' }, { id: 5, name: 'Aprobado con desembolso' }
                                        ]
                                },
                                { label: "Cliente por cédula", control: "identification", type: "text" },
                                { label: "Solicitud por código", control: "creditRequestId", type: "text" },
                                { label: 'Por código proveedor', control: 'codeCommerce', type: 'text', },
                        ],
                        buttonsSearch: [{ label: "Exportar", icon: "fa-solid fa-file-export", action: "export", color: 'success' }]
                }
        }
        private subscriptions: Subscription[] = [];

        constructor(
                private creditsRequestsService: CreditRequestService,
                private loaderService: LoaderService,
                private alertService: ModalServiceAlert,
                private tableService: TableService,
                private customerService: CustomersService,
                private modalService: NgbModal,
                private cdr: ChangeDetectorRef,
        ) { }

        /**
         * Inicializa el componente.
         */
        ngOnInit() {
                this.refreshQueryParams();
                this.searchCreditsRequests();
                this.subscribeToTableActions();
        }

        /**
         * Limpia las suscripciones al destruir el componente.
         */
        ngOnDestroy() {
                this.subscriptions.forEach(sub => sub.unsubscribe());
        }

        /**
         * Reinicia los parámetros de consulta.
         */
        refreshQueryParams() {
                this.queryParams = { dateStart: '', dateEnd: '', state: '', identification: '', creditRequestId: '', codeCommerce: '' };
        }

        /**
         * Busca las solicitudes de crédito.
         */
        searchCreditsRequests() {
                toggleLoader(this.loaderService, true);
                this.creditsRequestsService.searchCreditsRequests(this.queryParams, this.currentPage).subscribe(
                        response => this.handleSearchResponse(response),
                        error => this.handleSearchError(error)
                );
        }

        /**
         * Maneja la respuesta exitosa de la búsqueda de solicitudes de crédito.
         * @param {any} response - La respuesta del servicio.
         */
        private handleSearchResponse(response: any) {
                toggleLoader(this.loaderService, false);
                this.dataTable.table.paginator = response.data;
                this.dataTable.table.body = this.formatCreditRequests(response.data.data) as any;
                this.tableService.infoTable(this.dataTable);
        }

        /**
         * Maneja el error en la búsqueda de solicitudes de crédito.
         * @param {any} error - El error recibido.
         */
        private handleSearchError(error: any) {
                toggleLoader(this.loaderService, false);
                this.alertService.openModal("Error", "Ocurrió un error al obtener la lista de solicitudes de crédito", "error");
        }

        /**
         * Formatea las solicitudes de crédito para su visualización.
         * @param {CreditRequestListInterface[]} creditRequests - Las solicitudes de crédito a formatear.
         * @returns {any[]} Las solicitudes de crédito formateadas.
         */
        private formatCreditRequests(creditRequests: CreditRequestListInterface[]) {
                return creditRequests.map(cr => ({
                        ...cr,
                        created: cr.created_at.split('T')[0],
                }));
        }               

        /**
         * Se suscribe a las acciones de la tabla.
         */
        private subscribeToTableActions() {
                this.subscriptions.push(
                        this.tableService.actionsInTable$.subscribe((data: any) => this.handleTableAction(data))
                );
        }

        /**
         * Maneja las acciones de la tabla.
         * @param {any} data - Los datos de la acción.
         */
        private handleTableAction(data: any) {
                if (!data) return;
                const actions: { [key: string]: () => void } = {
                        'view': () => this.showModalInfoCustomer(data.data.customer.id, data.data.id),
                        'comments-observations': () => this.showModalCreditRequestComments(data.data.id),
                        'return-to-request': () => this.returnToStudy(data.data.id),
                        'page': () => { this.currentPage = data.data.page; this.searchCreditsRequests(); },
                        'refresh': () => { this.refreshQueryParams(); this.currentPage = 1; this.searchCreditsRequests(); },
                        'search': () => this.handleSearch(data.data.form),
                        'export': () => this.searchCreditsRequestsForExport(),
                };
                actions[data.action]?.();
        }

        /**
         * Maneja la acción de búsqueda.
         * @param {any} form - Los datos del formulario de búsqueda.
         */
        private handleSearch(form: any) {
                this.currentPage = 1;
                form.action = 'buscar';
                this.queryParams = form;
                this.searchCreditsRequests();
        }



        /**
         * Muestra el modal con la información del cliente.
         * @param {number | undefined} customerId - El ID del cliente.
         * @param {number} creditRequestId - El ID de la solicitud de crédito.
         */
        showModalInfoCustomer(customerId: number | undefined, creditRequestId: number) {
                if (!customerId) return;
                toggleLoader(this.loaderService, true);
                this.customerService.getCustomerInfo(customerId).subscribe(
                        customerInfo => this.openCustomerInfoModal(customerInfo, creditRequestId),
                        error => this.handleCustomerInfoError(error)
                );
        }

        /**
         * Abre el modal con la información del cliente.
         * @param {any} customerInfo - La información del cliente.
         * @param {number} creditRequestId - El ID de la solicitud de crédito.
         */
        private openCustomerInfoModal(customerInfo: any, creditRequestId: number) {
                const modalRef = this.modalService.open(CustomerInfoShowModalComponent, { size: "xl" });
                modalRef.componentInstance.title = "Actualizar cliente";
                modalRef.componentInstance.customerInfo = customerInfo.data;
                modalRef.componentInstance.creditRequestId = creditRequestId;
                this.cdr.detectChanges();
                toggleLoader(this.loaderService, false);
        }

        /**
         * Maneja el error al obtener la información del cliente.
         * @param {any} error - El error recibido.
         */
        private handleCustomerInfoError(error: any) {
                toggleLoader(this.loaderService, false);
                this.alertService.openModal("Error", "No se pudo obtener la información del cliente", "error");
        }

        /**
         * Muestra el modal de comentarios de la solicitud de crédito.
         * @param {number} id - El ID de la solicitud de crédito.
         */
        showModalCreditRequestComments(id: number) {
                const modalRef = this.modalService.open(CreditsRequestsCommentsComponent);
                modalRef.componentInstance.creditRequestId = id;
        }

        /**
         * Inicia el proceso de devolución a estudio de una solicitud.
         * @param {number} id - El ID de la solicitud de crédito.
         */
        returnToStudy(id: number) {
                const modalRef = this.modalService.open(DynamicFormModalComponent, { centered: true });
                this.setupReturnToStudyModal(modalRef, id);
        }

        /**
         * Configura el modal para la devolución a estudio.
         * @param {any} modalRef - La referencia al modal.
         * @param {number} id - El ID de la solicitud de crédito.
         */
        private setupReturnToStudyModal(modalRef: any, id: number) {
                modalRef.componentInstance.title = 'Devolución a estudio';
                modalRef.componentInstance.fields = [{ type: 'textarea', label: 'Ingrese el motivo de devolución', key: 'motivoDevolucion' }];
                modalRef.componentInstance.validations = { motivoDevolucion: this.validateMotivoDevolucion };
                modalRef.componentInstance.onConfirm.subscribe((data: any) => this.handleReturnToStudy(id, data.motivoDevolucion));
        }

        /**
         * Valida el motivo de devolución.
         * @param {any} value - El valor a validar.
         * @returns {string | null} El mensaje de error o null si es válido.
         */
        private validateMotivoDevolucion(value: any) {
                return value ? null : 'El motivo de devolución es obligatorio';
        }

        /**
         * Maneja la devolución a estudio de una solicitud.
         * @param {number} id - El ID de la solicitud de crédito.
         * @param {string} motivoDevolucion - El motivo de la devolución.
         */
        private handleReturnToStudy(id: number, motivoDevolucion: string) {
                toggleLoader(this.loaderService, true);
                this.creditsRequestsService.returnToStudy(id, motivoDevolucion).subscribe(
                        () => this.handleReturnToStudySuccess(),
                        error => this.handleReturnToStudyError(error)
                );
        }

        /**
         * Maneja el éxito de la devolución a estudio.
         */
        private handleReturnToStudySuccess() {
                toggleLoader(this.loaderService, false);
                this.alertService.openModal('Éxito', 'La solicitud ha regresado a estudio.', 'success')
                        .subscribe(() => this.searchCreditsRequests());
        }

        /**
         * Maneja el error en la devolución a estudio.
         * @param {any} error - El error recibido.
         */
        private handleReturnToStudyError(error: any) {
                toggleLoader(this.loaderService, false);
                this.alertService.openModal("Error", "No se pudo devolver la solicitud a estudio", "error");
        }

        /**
         * Busca las solicitudes de crédito para exportar.
         * @param {any} dataForm - Los datos del formulario de búsqueda.
         */
        searchCreditsRequestsForExport() {
                toggleLoader(this.loaderService, true);
                this.creditsRequestsService.searchCreditsRequestsForExport(this.queryParams).subscribe(
                        resp => this.handleExportResponse(resp),
                        error => this.handleExportError(error)
                );
        }

        /**
         * Maneja la respuesta exitosa de la exportación.
         * @param {any} resp - La respuesta del servicio.
         */
        private handleExportResponse(resp: any) {
                toggleLoader(this.loaderService, false);
                if (resp.ok) {
                        this.exportCreditsRequestsToExcel(resp.data);
                } else {
                        this.alertService.openModal("Error", "Hubo un error al realizar la consulta", "error");
                }
        }

        /**
         * Maneja el error en la exportación.
         * @param {any} error - El error recibido.
         */
        private handleExportError(error: any) {
                toggleLoader(this.loaderService, false);
                this.alertService.openModal("Error", "Hubo un error en la solicitud", "error");
                console.error(error);
        }

        /**
         * Exporta las solicitudes de crédito a Excel.
         * @param {CreditRequestListInterface[]} data - Los datos a exportar.
         */
        exportCreditsRequestsToExcel(data: CreditRequestListInterface[]) {
                const columns = [
                        { header: 'Codigo', key: 'id' },
                        { header: 'Fecha solicitud', key: 'created' },
                        { header: 'Nombre', key: 'customer_name' },
                        { header: 'Apellido', key: 'customer_last_name' },
                        { header: 'Cédula', key: 'customer_identification' },
                        { header: 'Analista', key: 'user_name' },
                        { header: 'Proveedor', key: 'shop_commerce_name' },
                        { header: 'Valor Solicitado', key: 'request_value' },
                        { header: 'Valor Aprobado', key: 'value_approve' },
                        { header: 'Estado', key: 'credit_request_state_name' },
                ];
                const timestamp = getCurrentTimestamp();
                const fileNameWithTimestamp = `solicitudes_credito_ziro_${timestamp}.xlsx`;
                exportToExcel(data, fileNameWithTimestamp, 'Solicitudes credito', columns, true);
                toggleLoader(this.loaderService, false);
        }
}
