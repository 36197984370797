import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustmersCodeOptService {

  private baseUrl: string = environment.apiUrlCustomer + '/credit-codes/all';

  /**
   * @param {HttpClient} http - El cliente HTTP para realizar peticiones
   */
  constructor(private http: HttpClient) { }

  /**
   * Obtiene los códigos OTP paginados y filtrados
   * @param {number} page - El número de página a obtener
   * @param {any} [filter] - Objeto con propiedades code e identification para filtrar
   * @returns {Observable<any>} Un Observable con la respuesta del servidor
   */
  getCodesOpt(page: number, filter: any): Observable<any> {
    let url = `${this.baseUrl}?page=${page}`;
    
    if (filter) {
      if (filter.code) {
        url += `&code=${filter.code}`;
      }
      
      if (filter.identification) {
        url += `&identification=${filter.identification}`;
      }
    }
    
    return this.http.get<any>(url);
  }

}
